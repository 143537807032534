<template>
  <div class="lesson">
    <!-- CEE -->
    <app-block-fiche-aide-fi :aide="cee">
      <template #montant="{ montant }">
        <p v-html="montant.p1"></p>
        <p v-html="montant.p2"></p>
      </template>
      <template #travaux>
        <div class="list-wrapper section-fiche-travaux">
          <div class="flex-icon-block" v-for="item in cee.travaux.items" :key="item.label">
            <img :src="item.icon"/>
            <div>
              <p class="big-text">{{ item.label }}</p>
              <p class="annotation" v-if="item.annotation" v-html="item.annotation"></p>
            </div>
          </div>
        </div>
      </template>
    </app-block-fiche-aide-fi>

    <!-- ECOPTZ -->
    <app-block-fiche-aide-fi :aide="ecoptz">
      <template #montant="{ montant }">
        <ul>
          <li v-for="item in montant" :key="item" v-html="item"></li>
        </ul>
      </template>
    </app-block-fiche-aide-fi>

    <app-button-layout class="next-button" @click="$emit('next-tab')">
      {{ $t('global.navigation.resume') }}
    </app-button-layout>
  </div>
</template>

<script setup>
import AppBlockFicheAideFi from '@/components/training/module1/part2/AppBlockFicheAideFi.vue'
import { useI18n } from 'vue-i18n'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'
import isolationIco from '@/assets/module1/part2/lesson/isolation-ico.svg'
import menuiserieIco from '@/assets/module1/part2/lesson/menuiserie-ico.svg'
import chauffageIco from '@/assets/module1/part2/lesson/chauffage-ico.svg'
import ventilationIco from '@/assets/module1/part2/lesson/ventilation-ico.svg'
import renoGlobalIco from '@/assets/module1/part2/lesson/reno-globale-ico.svg'

const { t } = useI18n()

const cee = {
  name: 'cee',
  label: t('module1.part2.aides.cee.title'),
  logo: require('@/assets/logos/aides/logo-cee.svg'),
  intro: t('module1.part2.aides.cee.intro'),
  logement: t('module1.part2.aides.cee.logement'),
  qui: t('module1.part2.aides.cee.qui'),
  montant: {
    p1: t('module1.part2.aides.cee.montant1'),
    p2: t('module1.part2.aides.cee.montant2')
  },
  travaux: {
    items: [
      {
        label: t('module1.part2.travaux.isol'),
        icon: isolationIco
      },
      {
        label: t('module1.part2.travaux.menuiserie'),
        icon: menuiserieIco
      },
      {
        label: t('module1.part2.travaux.chauffage'),
        icon: chauffageIco
      },
      {
        label: t('module1.part2.travaux.vmcSimple'),
        icon: ventilationIco
      },
      {
        label: t('module1.part2.travaux.renoAmpleur'),
        icon: renoGlobalIco,
        annotation: t('module1.part2.aides.cee.renoAmpleurAnnotation')
      },
      {
        label: t('module1.part2.aides.cee.renoGlobalCollectif'),
        icon: renoGlobalIco,
        annotation: t('module1.part2.aides.cee.renoGlobalCollectifAnnotation')
      }
    ]
  },
  obtention: [
    t('module1.part2.aides.cee.obtention.item1'),
    t('module1.part2.aides.cee.obtention.item2')
  ]
}

const ecoptz = {
  name: 'ecoptz',
  label: t('module1.part2.aides.ecoptz.title'),
  logo: require('@/assets/logos/aides/logo-ecoptz.svg'),
  intro: t('module1.part2.aides.ecoptz.intro'),
  logement: t('module1.part2.aides.ecoptz.logement'),
  qui: t('module1.part2.aides.ecoptz.qui'),
  montant: [
    t('module1.part2.aides.ecoptz.montant1'),
    t('module1.part2.aides.ecoptz.montant2'),
    t('module1.part2.aides.ecoptz.montant3'),
    t('module1.part2.aides.ecoptz.montant4'),
    t('module1.part2.aides.ecoptz.montant5')
  ],
  travaux: {
    intro: t('module1.part2.aides.ecoptz.travauxIntro'),
    renoAmpleurAnnotation: t('module1.part2.aides.ecoptz.renoAmpleurAnnotation'),
    items: [
      'isolation',
      'menuiserie',
      'chauffage',
      'vmc',
      'renoAmpleur'
    ]
  }
}
</script>

<style scoped lang="scss">

</style>
